<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Financials from '@/services/Financials';
    import moment from "moment";
    import modalViewOrder from  '@/components/modals/orders/modalViewOrder';
    import modalViewTransaction from "@/components/modals/financials/modalViewTransaction";
    import DatePicker from "vue2-datepicker";

    export default {
        components: { Layout, PageHeader , modalViewOrder  , modalViewTransaction},
        page: {
            title: "Transactions",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                error: null,
                showTables: false ,
                tableData: [],
                tableData1: [],
                tableData2: [],
//                tableData3 : [] ,
//                tableData4 : [] ,
                tableData5 : [] ,
                title: "Stripe Payouts",
                items: [
                    {
                        text: "Stripe Payouts",
                        href: "/financials/stripe_payouts",
                    },
                    {
                        text:"Transactions",
                        active: true,
                    }

                ],
                filterInput: {
                    payouts : ''
                },
                guid: '',
                legacy: '',
                order_type: '',
                transactionData: [],
                transactionId: '',
                isBusy: false,
                isBusy1: false,
                isBusy2: false,
//                isBusy3: false,
//                isBusy4: false,
                isBusy5: false,
                totalRows: 0,
                totalRows1: 0,
                totalRows2: 0,
                totalRows3: 0,
//                totalRows4: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                payouts: [] ,
                sortBy: "date",
                sortDesc: false,
                fields_payout_summary: [
                    { key: "id", label: "" },
                    { key: "count", label: "Count" },
                    { key: "gross", label: "Gross" },
                    { key: "fees", label: "Fees" },
                    { key: "total", label: "Net" }
                ],
                fields_payout_by_type: [
                    { key: "type", label: "Type" },
                    { key: "description", label: "Description" },
                    { key: "amount", label: "Gross" },
//                    { key: "fee", label: "Stripe Fee" },
                    { key: "app_fee", label: "SC Fee" },
                    { key: "net", label: "Net" }
                ] ,
                fields_payout_transactions : [
                    { key: "created", label: "Date" },
                    { key: "transaction_id", label: "Transaction Id" },
                    { key: "order_id", label: "Order Id" },
                    { key: "type", label: "Type" },
                    { key: "amount", label: "Amount" },
                    { key: "first_name", label: "Customer" },
                    { key: "status", label: "Status" },
                ] ,
//                fields_transactions_not_in_twleve: [
//                    { key: "created", label: "Date" },
//                    { key: "transaction_id", label: "Transaction Id" },
//                    { key: "order_id", label: "Order Id" },
//                    { key: "amount", label: "Amount" },
//                    { key: "first_name", label: "Customer" },
//                    { key: "status", label: "Status" },
//                ],
                fields_payout_refunds:[
                    { key: "created", label: "Date" },
                    { key: "transaction_id", label: "Transaction Id" },
                    { key: "order_id", label: "Order Id" },
                    { key: "type", label: "Type" },
                    { key: "amount", label: "Amount" },
                    { key: "stripe_transaction_amount", label: "Refunded" },
                    { key: "reason", label: "Refund Reason" },
                    { key: "sro_crm_id", label: "SRO ID" },
                    { key: "first_name", label: "Customer" },
                    { key: "status", label: "Status" },
                ]

            };
        },
        created() {

            this.getAllPayouts();
        },


        computed: {
            formattedPayouts() {
                return this.payouts.map(payout => {
                    const date = payout.arrival_date.split(' ')[0];
                    return {
                        id: payout.id,
                        display: `${date} - ${payout.amount.toFixed(2)} - ${payout.status}`
                    };
                });
            } ,
            payoutTitle() {
                if(this.filterInput.payout) {
                    return `Payout details - ${this.filterInput.payout}`;
                } return '' ;
            } ,
            rows() {
                return this.tableData.length;
            },

        },
        mounted() {
            this.totalRows2 = this.tableData2.length;

        },
        methods: {

            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.totalRows1 = filteredItems.length;
                this.totalRows2 = filteredItems.length;
                this.currentPage = 1;
            },


            async searchByFilter() {
                if (this.filterInput.payout) {
                    this.showTables = true;
                    this.getPayoutSummary();
                    this.getPayoutRefunds();
                    this.getTransactions();
                    this.getPayoutsByType();
//                    this.getTransactionsNotInEwallet();
                } else {
                    this.showTables = false;
                }
            },
            async getTransactions(){
                try {
                    this.toggleBusy();
                    this.isBusy2 = true;
                    var filters = this.getFilters();
                    const response = await Financials.getTransactions(filters);
                    this.tableData2 = response.data;
                    this.totalRows2 = this.tableData2.length;
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData2 = []
                    this.totalRows2 = 0
                }
                finally {
                    this.isBusy2 = false
                }
            },
            async getPayoutRefunds(){
                try {
                    this.toggleBusy();
                    var filters = this.getFilters();
                    this.isBusy5 = true;
                    const response = await Financials.getPayoutRefunds(filters);
                    this.tableData5 = response.data;
                    this.totalRows5 = this.tableData5.length;
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData5 = [];
                    this.totalRows5 = 0;
                }
                finally {
                    this.isBusy5 = false
                }
            },
            async getPayoutSummary(){
                try {
                    this.toggleBusy();
                    this.isBusy1 = true
                    var filters = this.getFilters();
                    const response = await Financials.getPayoutSummary(filters);
                    this.tableData1 = response.data;
                    this.totalRows1 = this.tableData.length;
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData1 = [];
                    this.totalRows1 = 0
                }
                finally {
                    this.isBusy1 = false
                }

            },

            async getPayoutsByType(){
                try {
                    this.toggleBusy();
                    this.isBusy = true;
                    var filters = this.getFilters();
                    const response = await Financials.getPayoutsByType(filters);

                    this.tableData = response.data.map(item => {
                        const appFee = parseFloat(item.app_fee) || 0;
                        const stripeFee = parseFloat(item.fee) || 0;
                        item.app_fee = (appFee + stripeFee).toFixed(2);
                        return item;
                    });

                    this.totalRows = this.tableData.length;
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = [];
                    this.totalRows = 0;
                } finally {
                    this.isBusy = false;
                }
            },
//            async getPayoutsByType(){
//                try {
//                    this.toggleBusy();
//                    this.isBusy = true
//                    var filters = this.getFilters()
//                    const response = await Financials.getPayoutsByType(filters)
//                    this.tableData = response.data
//                    this.totalRows = this.tableData.length
//                    this.toggleBusy();
//                } catch (error) {
//                    this.toggleBusy();
//                    this.error = error.response.data.error ? error.response.data.error : "";
//                    this.tableData = []
//                    this.totalRows = 0
//                }
//                finally {
//                    this.isBusy = false
//                }
//            } ,
//            async getTransactionsNotInEwallet(){
//                try {
//                    this.toggleBusy();
//                    this.isBusy3 = true;
//                    var filters = this.getFilters()
//                    const response = await Financials.getTransactionsNotInEwallet(filters)
//                    this.tableData3 = response.data
//                    this.totalRows3 = this.tableData.length
//                    this.toggleBusy();
//                } catch (error) {
//                    this.toggleBusy();
//                    this.error = error.response.data.error ? error.response.data.error : "";
//                    this.tableData3 = []
//                    this.totalRows3 = 0
//                }
//                finally {
//                    this.isBusy3 = false
//                }
//            },

            async getAllPayouts(){
                  try{
                      const response = await Financials.getAllPayouts();
                      this.payouts = response.data;

                  }
                  catch(error){
                      this.error = error.response.data.error ? error.response.data.error : "";

                  }
            },
            isBoldRow(row) {
                const boldRows = ["Total", "Adjustment", "Payout Charges", "Payout Refunds", "Payout Total"];
                return boldRows.includes(row.type);
            },
            modalViewOrder(data){
                this.$bvModal.show("view_order");
                this.guid = data.guid;
                this.legacy = typeof data.legacy !== 'undefined' ? data.legacy : 0;
                this.order_type = data.order_types;
            },
            
            modalViewTransaction(transactionId){
                this.$bvModal.show("view_transaction");
                this.transactionId = transactionId;
            },

            getFilters(){
                var filter_string = '';
                if(this.filterInput.payout) {
                    filter_string += (this.filterInput.payout) ? '&payout_id=' +this.filterInput.payout : "";
                }
                return filter_string = filter_string.substring(1);
            },


        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">


                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-5">
                                    <b-form-group label="Payouts" label-for="payouts" class="mb-3 text-center">
                                        <select v-model="filterInput.payout" class="form-select" id="payout">
                                            <option v-for="payout in formattedPayouts" :key="payout.id" :value="payout.id">
                                                {{ payout.display }}
                                            </option>
                                        </select>
                                    </b-form-group>

                                </div>
                                <div class="col-md-2"></div>
                                    <div class="col-md-5" style="margin-top:17px;">
                                        <div class="custom-accordion">
                                                    <div class="row">
                                                        <div class="col d-inline-flex">
                                                            <button type="button" class="btn btn-success me-2 w-lg" @click="searchByFilter">Filter</button>
                                                            <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                                                        </div>
                                                    </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>
                <template v-if="showTables">
                <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="table-responsive mb-0 col-6">
                            <h5>{{ payoutTitle }}</h5>
                            <b-table
                                    :items="tableData1"
                                    :busy="isBusy1"
                                    :fields="fields_payout_summary"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter-included-fields_transaction_summary="filterOn"
                                    @filtered="onFiltered"
                                    show-empty
                                    empty-text="No Data Found"
                            >


                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>

                        <div class="table-responsive mb-0 col-6">
                            <h5>Charges By type</h5>
                            <b-table
                                    :items="tableData"
                                    :busy="isBusy"
                                    :fields="fields_payout_by_type"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter-included-fields_transaction_summary="filterOn"
                                    @filtered="onFiltered"
                                    show-empty
                                    empty-text="No Data Found"
                            >
                                <template #cell()="data">
                                    <td :style="{ fontWeight: isBoldRow(data.item) ? 'bold' : 'normal' }">
                                        {{ data.value }}
                                    </td>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                      </div>



                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                            >
                                <label class="d-inline-flex align-items-center">
                                    Search:
                                    <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                         <!--End search -->


                        <div class="table-responsive mb-0 ">
                            <h5> Transaction Detail</h5>
                            <b-table
                                    :items="tableData2"
                                    :busy="isBusy2"
                                    :fields="fields_payout_transactions"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter-included-fields_payout_transactions="filterOn"
                                    :filter="filter"
                                    @filtered="onFiltered"
                                    show-empty
                                    empty-text="No Data Found"
                            >

                                <template v-slot:cell(transaction_id)="data">

                                    <a
                                            href="javascript:void(0);"
                                            @click="modalViewTransaction(data.item.transaction_id)"
                                            class="px-2 text-primary"
                                            v-b-tooltip.hover
                                            title="View Transaction Details"
                                    >
                                        {{data.item.transaction_id}}
                                    </a>

                                </template>

                                <template v-slot:cell(order_id)="data">
                                    <li class="list-inline-item" v-if="data.item.guid">
                                        <a
                                                href="javascript:void(0);"
                                                @click="modalViewOrder(data.item)"
                                                class="px-2 text-primary"
                                                v-b-tooltip.hover
                                                title="View Order"
                                        >
                                            {{data.item.order_id}}
                                        </a>
                                    </li>
                                    <li class="list-inline-item" v-else>
                                        <!--<a-->
                                                <!--href="javascript:void(0);"-->
                                                <!--@click="modalViewOrder(data.item)"-->
                                                <!--class="px-2 text-primary"-->
                                                <!--v-b-tooltip.hover-->
                                                <!--title="View Order"-->
                                        <!--&gt;-->
                                            {{data.item.order_id}}
                                        <!--</a>-->
                                    </li>                                </template>

                                <template v-slot:cell(first_name)="data">
                                     <router-link title="View Customer" :to="{ path: `/customers/profile/${data.item.customer_id}`}">
                                                     {{ data.item.first_name ? (data.item.first_name + ' ' + data.item.last_name) : '' }}
                                      </router-link>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <span class="font-size-16" v-html="format_status(data.item.status)"></span>
                                </template>


                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows2"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!--<div class="table-responsive mb-0 ">-->
                            <!--<h5> Transactions not processed in EWALLET PROVIDER - TWELVE</h5>-->
                            <!--<b-table-->
                                    <!--:items="tableData3"-->
                                    <!--:busy="isBusy3"-->
                                    <!--:fields="fields_transactions_not_in_twleve"-->
                                    <!--responsive="sm"-->
                                    <!--:per-page="perPage"-->
                                    <!--:current-page="currentPage"-->
                                    <!--:sort-by.sync="sortBy"-->
                                    <!--:sort-desc.sync="sortDesc"-->
                                    <!--:filter-included-fields_payout_refunds="filterOn"-->
                                    <!--@filtered="onFiltered"-->
                                    <!--show-empty-->
                                    <!--empty-text="No Data Found"-->
                            <!--&gt;-->

                                <!--<template v-slot:cell(first_name)="data">-->
                                    <!--<router-link title="View Customer" :to="{ path: `/customers/profile/${data.item.customer_id}`}">-->
                                        <!--{{ data.item.first_name ? (data.item.first_name + ' ' + data.item.last_name) : '' }}-->
                                    <!--</router-link>-->
                                <!--</template>-->
                                <!--<template #table-busy>-->
                                    <!--<div class="text-center text-danger my-2">-->
                                        <!--<b-spinner class="align-middle"></b-spinner>-->
                                        <!--<strong>Loading...</strong>-->
                                    <!--</div>-->
                                <!--</template>-->
                            <!--</b-table>-->
                        <!--</div>-->
                        <!--<div class="row">-->
                            <!--<div class="col">-->
                                <!--<div class="dataTables_paginate paging_simple_numbers float-end">-->
                                    <!--<ul class="pagination pagination-rounded mb-0">-->
                                        <!--&lt;!&ndash; pagination &ndash;&gt;-->
                                        <!--<b-pagination-->
                                                <!--v-model="currentPage"-->
                                                <!--:total-rows="totalRows3"-->
                                                <!--:per-page="perPage"-->
                                        <!--&gt;</b-pagination>-->
                                    <!--</ul>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->



                        <div class="table-responsive mb-0 ">
                            <h5>Payout Refunds</h5>
                            <b-table
                                    :items="tableData5"
                                    :busy="isBusy5"
                                    :fields="fields_payout_refunds"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter-included-fields_payout_refunds="filterOn"
                                    @filtered="onFiltered"
                                    show-empty
                                    empty-text="No Data Found"
                            >

                                <template v-slot:cell(first_name)="data">
                                    <router-link title="View Customer" :to="{ path: `/customers/profile/${data.item.customer_id}`}">
                                        {{ data.item.first_name ? (data.item.first_name + ' ' + data.item.last_name) : '' }}
                                    </router-link>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows3"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                </template>
            </div>
        </div>
        <!-- MODALS -->
        <modalViewOrder :guid="this.guid" :legacy="this.legacy" :order_type="this.order_type"></modalViewOrder>
        <modalViewTransaction :transactionId="transactionId"></modalViewTransaction>

    </Layout>
</template>

